import {
  SET_SHOW_BACK_BUTTON,
  SET_SHOW_RELOAD_BUTTON,
  SET_REFRESH_TRANSACTIONS_LIST,
  SET_DAY_STATUS,
  SET_OPEN_CLOSE_DAY,
  SHOW_NAVBAR_PANEL,
  SET_LOGOUT_COUNTER,
  LOGOUT
} from "../actions";
import { AnyAction } from "redux";


const initialState = {
  showBackButton: false,
  showReloadButon: false,
  refreshTransactionsList: false,
  dayIsOpen: false,
  showModalOpenCloseDay: false,
  showModalLogout: false,
  token: "",
  showNavbarPanel: true,
  logoutCounter: 5,
};

export default function addressesReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case SHOW_NAVBAR_PANEL:
      return {
        ...state,
        showNavbarPanel: action.payload
      };
    case SET_SHOW_BACK_BUTTON:
      return {
        ...state,
        showBackButton: action.payload
      };

    case SET_LOGOUT_COUNTER:
      return {
        ...state,
        logoutCounter: action.payload
      };
    case SET_REFRESH_TRANSACTIONS_LIST:
      return {
        ...state,
        refreshTransactionsList: action.payload
      };

    case SET_SHOW_RELOAD_BUTTON:
      return {
        ...state,
        showReloadButon: action.payload
      };

    case SET_DAY_STATUS:
      return {
        ...state,
        dayIsOpen: action.payload
      };
    case SET_OPEN_CLOSE_DAY:
      return {
        ...state,
        showModalOpenCloseDay: action.payload
      };
    case LOGOUT:
      return {
        ...state,
        showModalLogout: action.payload
      };

    default:
      return state;
  }
}
