import {
    CHANGE_LANGUAGE,
    SET_SHOW_BACK_BUTTON,
    SET_SHOW_RELOAD_BUTTON,
    SET_REFRESH_TRANSACTIONS_LIST,
    SET_DAY_STATUS,
    SET_OPEN_CLOSE_DAY,
    SHOW_NAVBAR_PANEL,
    SET_LOGOUT_COUNTER,
    LOGOUT
} from '../actions';
import i18n from "../../i18n";
import { storePrefLang } from "../services/Prefs";
import moment from "moment";

export const setShowBackButton = (state) => {
    return { type: SET_SHOW_BACK_BUTTON, payload: state };
}

export const setShowReloadButton = (state) => {
    return { type: SET_SHOW_RELOAD_BUTTON, payload: state };
}

export const setRefreshTransactionsList = (state) => {
    return { type: SET_REFRESH_TRANSACTIONS_LIST, payload: state };
}

export const setLogoutCounter = (state) => {
    return { type: SET_LOGOUT_COUNTER, payload: state };
}

export const setShowNavbarPanel = (state) => {
    return { type: SHOW_NAVBAR_PANEL, payload: state };
}

export const setDayStatus = (state) => {
    return { type: SET_DAY_STATUS, payload: state };
}

export const setShowModalOpenCloseDay = (state) => {
    return { type: SET_OPEN_CLOSE_DAY, payload: state };
}

export const setShowModalLogout = (state) => {
    return { type: LOGOUT, payload: state };
}

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
    storePrefLang(lng);
    return { type: CHANGE_LANGUAGE, payload: lng };
}
