import React, { useState, useEffect } from "react";
import { ITransactionsDetails } from "../../interfaces";
import classNames from "classnames"
import { setShowBackButton, setShowNavbarPanel } from "../../api/actionCreators/mainActionCreator"
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { NAV, capitalizeFirstLetter, amountFormatter } from "../../constants"
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { TransactionCompleteIcon } from "../../svg-icons/icon-transaction-complete"
import { TransactionReturnedIcon } from "../../svg-icons/icon-transaction-returned"
import axios from "../../api/services/Api"
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import styles from "./transactions-details.module.scss";
import moment from "moment";

const TransactionsDetails = (props: ITransactionsDetails) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { trxId } = useParams();
  const emptyObj = {
    id: null,
    amount: null,
    ccy: null,
    date: null,
    type: null,
    rrn: null,
    approvalCode: null,
    mсс: null,
    swiftMessageType: null,
    swiftMessageId: null,
    swiftPayerBank: null,
  }
  const [trxInfo, setTrxInfo] = useState(emptyObj)

  useEffect(() => {

    axios
      .get(`/pos/api/v1/tx/${trxId}`)
      .then(res => {
        const { result, txInfo } = res.data

        if (result === "success") {
          setTrxInfo(txInfo)
        } else {
          enqueueSnackbar(t(capitalizeFirstLetter(result)))
        }
      })

  }, [trxId, t])

  useEffect(() => {
    dispatch(setShowBackButton(true))
    dispatch(setShowNavbarPanel(false))
  }, [dispatch])

  useEffect(() => () => {
    dispatch(setShowBackButton(false))
    dispatch(setShowNavbarPanel(true))
  }, [dispatch]);


  return (
    <AppMainLayout activeNav={NAV.TRANSACTIONS_DETAILS} backButtonUrl={"/app/transactions"}>
      <div className={styles.transaction_details_container}>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Transaction_id")}:
          </div>
          <div className={styles.bold}>
            {/* {t("Terminal")}  */}
            {trxInfo.id}
          </div>

        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t('Amount')}:
          </div>
          <div className={styles.bold}>
            {amountFormatter(trxInfo.amount)} <span className={styles.uppercase}>{trxInfo.ccy}</span>
          </div>
        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Status")}:
          </div>
          <div
            className={classNames({
              [`${styles.bold}`]: true,
              [`${styles.green}`]: trxInfo.type === "paid",
              [`${styles.gray}`]: trxInfo.type !== "paid",
            })}
          >
            {trxInfo.type === "paid" ? t("Paid") : trxInfo.type === "revert" ? t("Revert") : t("Partial_revert")}
            {trxInfo.type === "paid" ?
              <TransactionCompleteIcon /> :
              trxInfo.type && <TransactionReturnedIcon />}
          </div>
        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Transaction_swift_type")}:
          </div>
          <div className={styles.bold}>
            {trxInfo.swiftMessageType}
          </div>

        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Transaction_swift_id")}:
          </div>
          <div className={styles.bold}>
            {trxInfo.swiftMessageId}
          </div>

        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Transaction_swift_bank")}:
          </div>
          <div className={styles.bold}>
            {trxInfo.swiftPayerBank}
          </div>

        </div>

        <div className={styles.transaction_details_line}>
          <div className={styles.transaction_line_container}>
            {t("Date")}:
          </div>
          <div className={styles.bold}>
            {moment(trxInfo.date).format("HH:mm, DD.MM.YYYY")}
          </div>

        </div>

      </div>
    </AppMainLayout>
  );
};
export default TransactionsDetails;
